@import "~bulma/sass/utilities/_all";
@import "~bulma/sass/base/_all";
@import "~bulma/sass/grid/_all";
@import "~bulma/sass/components/navbar";
@import "~bulma/sass/components/level";
@import "~bulma/sass/layout/_all";
@import "~bulma/sass/elements/image";
@import "~bulma/sass/elements/title";
@import "~bulma/sass/elements/table";
@import "~bulma-divider/src/sass/index";

// Table width
$widthAmounts: (2.5, 5, 7.5, 10, 20, 30, 33.33, 40, 50, 66.66, 100); // Just add the numbers here, you can use points too.
$widthUnit: "%"; // Add the unit here (rem|em|px|%)

@each $width in $widthAmounts {
	$i: index($widthAmounts, $width);
	.table thead th.is-#{$i} {
		width: #{$width}#{$widthUnit} !important;
	}
}