@import 'variables';

.mat-snack-bar-container {
  &.error, &.notification {
    margin-left: 24px + $sidenav-admin-mini-width;
  }

  &.error {
    background: $error-invalid-color;
    color: $secondary-theme-color-lighter;
  }

  &.notification {
    background: $notification-background-color;
    color: $notification-color;
  }
}
