/* Remove animation from mat-checkbox */

.mat-checkbox-ripple .mat-ripple-element {
    display: none;
}
	
.mat-checkbox-background,
.mat-checkbox-checkmark-path {
  transition: none !important;
  animation: none !important;
}
