@import 'variables';
@import 'header';
@import 'colors';

mat-card {
  // box-shadow: 0 6px 5px -1px $black-background-alpha1, 0 -1px 10px 0 $black-background-alpha1 !important;
  margin-bottom: 1em;
  @media screen and (max-width: 486px) {
    padding: 0.5rem;
  }

  &:hover {
    box-shadow: 0 12px 10px -1px $black-background-alpha1, 0 -1px 20px 0 $black-background-alpha1;
  }

  .mat-card-header-text{
    margin: 0 5px;
  }

  // Style events cards
  &.event {
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    width: 100%;

    &:not([class*='#{$mat-elevation-prefix}']){
      box-shadow: none;
      border-radius: 8px;
      border: 2px solid transparent;
    }

    a {
      padding: 0;
      display: flex;
      flex-direction: row;

      .horizontal-align{
        float: right;
      }

      .information {
        width: 100%;
      }

      .warning {
        width: 40%;
        align-self: center;
        float: right;
        padding: 1rem 0 0.5rem 2rem;
      }
      .warning + .information {
        width: 60%; /* override */
      }


      @media screen and (max-width: $tablet-breakup-width){
        flex-direction: column;

        .information{
          width: 100%;
        }

        .warning{
          width: 100%;
          text-align: center;
          padding: 1rem 2rem 0.5rem 2rem;
        }

        .horizontal-align {
          float:none;
        }
      }

      &:link {
        text-decoration: inherit;
        color: initial;
      }

      &:visited {
        text-decoration: inherit;
        color: initial;
      }

      .warning-public-at {
        background-color: $track-not-open-color;
        border-radius: 10rem;
        padding: .5rem 1rem;
        text-align: center;
        @extend h5;
      }
    }

    mat-card-title {
      margin-bottom: 0.5rem;
    }

    mat-card-subtitle {
      margin-bottom: 0.25rem;
    }

    .float-right {
      float: right;
    }

    .left-button {
      margin-left: 6px;
    }
  }

  &.subconferences {
    margin-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    background: $event-card-background;
    width: 100%;
  }


  &.admin-event {
    box-shadow: 0 0 0 0 !important;
    border-radius: 8px;
    width: 100%;
    border-width: 1px;
    border-style: solid;

    padding: 0 !important;
    margin-bottom: 1em;

    mat-card-header {
      background-color: $mat-card-header-color;
      color: $sidebar-label-color;

      @extend h2;
      font-weight: 600 !important;

      width: 100%;

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      padding: 0.25rem;
      margin-left: 0px;

      .mat-icon-button {
        width: 25px;
        height: 25px;
        margin-top: 1px;

        line-height: normal;

        a {
            color:black;
        }
      }

      mat-spinner {
        margin-left: 0.5rem;
      }
    }

    mat-card-content {
      padding: 1rem;
      margin: 0;

      mat-expansion-panel {
        margin: 0.5rem 0;

        mat-action-row {
          padding: 1rem;
        }
      }
    }

    mat-card-actions {
      margin: 0 !important;
      padding: 1rem !important;
    }

    table {

      .mat-footer-cell {
        border-bottom-width: 0;
      }

      .mat-header-cell {
        border-bottom-color: $event-card-line-separator;
      }

      .mat-cell {
        border-bottom-color: $event-card-line-separator;
      }
    }
  }

  &.base-card {
    box-shadow: 0 0 0 0 !important;
    width: 100%;

    padding: 0;
    margin-bottom: 2em;

    mat-card-header {
      @extend h2;
      font-weight: 600 !important;

      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      padding: 0.25rem;
      margin-left: 0px;

      .closed-header {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      .mat-icon-button {
        width: 25px;
        height: 25px;
        margin-top: 1px;
        margin-right: 5px;

        font-size: 1rem;
        line-height: normal;

        .mat-icon {
          // text-align: left;
          font-size: 16px;
        }

        a {
            color:black;
        }
      }

      mat-spinner {
        margin-left: 0.5rem;
      }
    }

    mat-card-content {
      padding: 1rem;
      margin: 0;
    }
  }
}

// Title with H3 properties
.card-title {
  @extend h3;
  color: $sidebar-label-color;
}

// Subtitle with H4 properties
.card-subtitle {
  @extend h4;
}

// Content with H5 properties
.card-content {
  @extend h5;
}


.section {
  padding-top: 0px !important;

  .section-title {
    @extend h3;
    font-size: 1rem;
    padding-bottom: 0.5rem;
  }

  .section-content {
    padding-left: 1.4rem;
  }
}
// .options {
//   display: inline-flex;
//   flex-direction: column;
// }

// .mat-slide-toggle {
//   margin-bottom: 0.5rem;
// }

// .column {
//   padding-bottom: 0;
// }
