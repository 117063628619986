@import 'colors';
@import 'variables';

::ng-deep {
    .mat-menu-panel {
        padding: 5px 0 !important;
        overflow: hidden !important;
    }
    .mat-menu-content {
        padding: 0 !important;
    }
    .mat-menu-item {
        border-radius: 5px !important;
        font-weight: 500 !important;
        height: 36px !important;
        margin: 2px 0 !important;
        padding: 0 12px !important;
    
        display: flex !important;
        align-items: center !important;
    
        .mat-icon {
            padding-top: 4px !important;
            margin-right: 8px !important;
        }
    }
    .mat-divider {
        margin: 0 0px !important;
    }
}
.menu-option {
    display: flex;
    .disabled-button {
    opacity: 0.5;
    pointer-events: none;
    mat-icon {
        opacity: 0.5;
        pointer-events: none;
    }
    }
    .mat-menu-item {
        margin: 2px 5px !important;
    }
}
