app-custom-form {
  app-multiline-text-input {
    app-custom-form {
      mat-form-field {
        padding-left: 2.5em;
      }
    }
  }

  app-table-form-fields-container {
    app-custom-form {
      mat-form-field {
        .mat-form-field-label-wrapper,
        .mat-form-field-underline {
          display: none;
        }
      }
    }
  }
}