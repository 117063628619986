@import 'variables';

$block-height: 60px;
$options-width: 210px;
$filters-width: 120px;

.main{
    &-header {
        // padding: 0.25rem;
        display: inline-flex;
        flex-wrap: nowrap;

        align-content: center;
        align-items: center;

        justify-content: space-between;

        width: 100%;

        .main-title {
            display: inline-flex;
            align-items: center;

            padding: 0;
            
            min-height: $block-height;

            flex-basis: content;
            flex-grow: 1;
            flex-shrink: 1;

            padding-top: 15px;
            padding-bottom: 15px;
        }


        .filterAndOptions {
            display: inline-flex;
            flex-wrap: nowrap;

            align-content: center;
            align-items: center;
            justify-content: flex-end;
            @media only screen and (max-width: $screen-iPhone8plus-portrait-width) {
              justify-content: center;
            }

            padding: 0;

            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;
        }

        .options {
            display: inline-flex;
            flex-direction: column;

            justify-content: center;

            padding: 0 0 0 0.5rem;
            height: $block-height;

            .mat-checkbox-label {
                margin-right: 0.5rem;
                height: 100%;
                font-size: 0.75rem;
                font-weight: 600;
                white-space: nowrap;
            }

            .notify-button {
                color: red;
            }
        }

        .filters {
            display: inline-flex;
            align-items: center;
            height: $block-height;

            button {
                margin:0 0.5rem;
            }

            .filterLabel {
              display: contents;
              @media only screen and (max-width: $screen-iPhone8plus-portrait-width) {
                display: none;
              }
            }
        }


        .action-buttons {
            display: inline-flex;
            flex-wrap: nowrap;
            align-content: center;
            align-items: center;
            justify-content: flex-end;

            height: $block-height;
            padding: 0;

            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;

            button {
                margin:0 0.25rem;
            }
        }


        @media screen and (max-width: $tablet-breakup-width) {
            .main-title {
                min-height: $block-height;
                height: auto;
            }

            .action-buttons {
                width: 100%;
                justify-content: flex-end;
            }
        }
    }

    &-body {
        padding: 0rem;
    }
}

.separator {
    padding: 0;

    hr {
        background-color: $navbar-selected-menu-color;
        color: $navbar-selected-menu-color;

        height: 1px;

        margin: 0;
        margin-bottom: 1rem;

        // width: 100vw;

        @media screen and (min-width: $desktop-width-sm) {
            // width: calc(100vw + (2 * (#{$lateral-padding-content-desktop-md})));
            margin-left: calc(-1 * #{$lateral-padding-content-desktop-md});
        }

        @media screen and (max-width: $desktop-width-sm) {
            // width: calc(100vw + (2 * (#{$lateral-padding-content-desktop-sm})));
            margin-left: calc(-1 * #{$lateral-padding-content-desktop-sm});
        }

        @media screen and (max-width: $tablet-breakup-width) {
            // width: calc(100vw + (2 * (#{$lateral-padding-content-tablet})));
            margin-left: calc(-1 * #{$lateral-padding-content-tablet});
        }

        @media screen and (max-width: $mobile-width) {
            // width: calc(100vw + (2 * (#{$lateral-padding-content-mobile})));
            margin-left: calc(-1 * #{$lateral-padding-content-mobile});
        }
    }

}

.section {
    padding: 1rem 0;

    &-header {
        margin-bottom: 0.5rem 0;
    }

    &-body {
        padding: 0.5rem 0;
    }
}

.subsection {
    padding: 1.25rem;

    &-header {
        margin-bottom: 0.5rem;
    }

    &-body {
        padding: 0.5rem;
    }
}

.subsubsection {
    padding: 1.5rem;
}
