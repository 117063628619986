.notification-menu {
  button.mat-menu-item {
    font-size: .8rem;
    font-weight: 500;
    white-space: normal;
    text-overflow: initial;
    height: auto;
    line-height: 1rem;
    padding: 1rem 2rem;

    &:not(:last-child) {
      border-bottom-style: solid;
      border-bottom-color: $main-theme-color;
      border-bottom-width: 1px;
    }

    .time {
      margin-top: .5em;

      mat-icon {
        font-size: 16px;
        line-height: 16px;
        height: 16px;
        width: 16px;

        margin-right: 4px;
      }

      span {
        font-size: 12px;
        line-height: 12px;

        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
