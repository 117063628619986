@import "~@angular/material/theming"; // Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core(); // Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

/* For use in src/lib/core/theming/_palette.scss */
/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
    50 : #d6eaff,
    100 : #aed7ff,
    200 : #7cbeff,
    300 : #55aaff,
    400 : #1f8fff,
    500 : #0080ff,
    600 : #0070ff,
    700 : #0060ff,
    800 : #0050ff,
    900 : #0040ff,
    A100 : #ffffff,
    A200 : #f2f8ff,
    A400 : #bfd9ff,
    A700 : #a6d0ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette1: (
    50 : #e1e9fa,
    100 : #b4c9f4,
    200 : #83a5ec,
    300 : #5180e4,
    400 : #2b65df,
    500 : #064ad9,
    600 : #0543d5,
    700 : #043acf,
    800 : #0332ca,
    900 : #0222c0,
    A100 : #e9ebff,
    A200 : #b6beff,
    A400 : #8391ff,
    A700 : #697aff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$theme-primary: (
  50: #e3e5eb,
  100: #babfcd,
  200: #8c95ac,
  300: #5e6a8b,
  400: #3c4a72,
  500: #192a59,
  600: #162551,
  700: #121f48,
  800: #0e193e,
  900: #080f2e,
  A100: #687dff,
  A200: #3551ff,
  A400: #0225ff,
  A700: #0020e8,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$theme-secondary: (
  50: #f0f1f3,
  100: #dadce1,
  200: #c1c4cd,
  300: #a8acb8,
  400: #969ba9,
  500: #83899a,
  600: #7b8192,
  700: #707688,
  800: #666c7e,
  900: #53596c,
  A100: #d5dffe,
  A200: #a3b8fd,
  A400: #6e90ff,
  A700: #557cff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff
  )
);

$theme-warn: (
  50: #f9e3e3,
  100: #f0b9b9,
  200: #e78a8a,
  300: #dd5b5b,
  400: #e43b3b,
  500: #e91414,
  600: #d31414,
  700: #c20e0e,
  800: #bc0b0b,
  900: #b00606,
  A100: #ffdbdb,
  A200: #ffa8a8,
  A400: #ff7575,
  A700: #ff5b5b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  )
);
// $font-family: "Open Sans", sans-serif;
$font-family: "Open Sans", sans-serif, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";

$app-warn: mat-palette($theme-warn); // Create the theme object (a Sass map containing all of the palettes).
// Tema claro
$app-primary-light: mat-palette($md-mcgpalette1);
$app-accent-light: mat-palette($md-mcgpalette1);
$app-theme-light: mat-light-theme($app-primary-light, $app-accent-light, $app-warn);
.light-theme {
  @include angular-material-theme($app-theme-light);
}

// Tema escuro
$app-primary-dark: mat-palette($md-mcgpalette0);
$app-accent-dark: mat-palette($md-mcgpalette0);
$app-theme-dark: mat-dark-theme($app-primary-dark, $app-accent-dark, $app-warn);
.dark-theme {
  @include angular-material-theme($app-theme-dark);
}


$app-typography: mat-typography-config($font-family);

@include angular-material-typography($app-typography);
