.home {
  .user-dashboard, .submissions, .reviews, .events, .myevents, .committees {
    .main-title {
      margin-left: .5rem;

      @media only screen and (max-width: $screen-iPhone8plus-portrait-width) {
        display: none;
      }
    }
    .vspacer {
      margin-top: 16px;
    }
  }
  .submissions, .reviews {
    padding-bottom: 16px;
  }
}

.admin {
  .event-admin .subconferences{
    .columns {
      margin-bottom: 0;
    }
  }
}
