@import "colors";

h1 {
    font-weight: 600;
    font-size: 1.25rem;
    letter-spacing: normal;
  }
  
  h2 {
    font-weight: 500;
    font-size: 1.15rem;
    letter-spacing: normal;
  }
  
  h3 {
    font-weight: 600;
    font-size: 1.05rem;
    letter-spacing: normal;
  }
  
  h4 {
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: normal;
  }
  
  h5 {
    font-weight: 100;
    font-size: 0.75rem;
    letter-spacing: normal;
  }