@import 'colors';
@import 'variables';

@mixin setSelectorLightDarkProp ($component, $selector, $prop, $lightValue, $darkValue) {
    .light-theme {
      #{$component} #{$selector} {
        #{$prop}: $lightValue;
      }
    }
    .dark-theme {
      #{$component} #{$selector} {
          #{$prop}: $darkValue;
      }
    }
  }
