@import 'variables';

html {
  overflow-y: auto;
}

body,
html {
  height: 100%;
  font-family: $font-family;

  &.modal-open {
    overflow-y: hidden;

    app-navbar {
      position: fixed;
    }
  }

  &.sidesheet-open {
    .sidesheet {
      display: block;
    }
  }

  &.cdk-global-scrollblock {
    app-fixed-top-progress-bar {
      display: none;
    }
  }

  mat-icon {
    font-size: 16px;
    @media screen and (max-width: $mobile-width) {
      font-size: 20px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 1.25rem;
  }

  .table.is-fullwidth {
    width: 100%;
    height: 100%;
  }
}

body {
  padding: 0;
  margin: 0;
}

app-admin {
  .mat-tab-body-content {
    padding: 0.5em 1em;
  }
}
