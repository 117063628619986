$main-theme-color: #192a59;
$main-theme-color-light: lighten($main-theme-color, 60);
$main-theme-color-darker: darken($main-theme-color, 10);
$secondary-theme-color: #babfcd;
$secondary-theme-color-light: #e3e5eb;
$secondary-theme-color-light-dark: #434343;
$secondary-theme-color-lighter: #fafafa; // Component Background
$accent-background: #064ad9; // #192a59; // #ffa500; // rgba(218, 218, 218, 0.075);
$accent-background-dark: rgb(0, 128, 255);
$checkbox-checked-background: $secondary-theme-color-light;
$accent-color-warning: #e91414;

$review-notes-background: #dff0d8;
$review-draft-background: rgba(51, 147, 255, .6);
$list-even-background: #eeeff2;
$list-background-hover: #d5d6d8;
$column-border-color: #848789;

$admin-background: white;
$admin-background-dark: black;
$admin-card: #F6F6F6;
$admin-card-border: lightgray;

$content-background: white;
$content-background-dark: black;
$content-color: #363636;
$content-color-dark: #F6F6F6;
$content-lightcolor: lighten ($content-color, 60);
$content-lightcolor-dark: lightgray;
$content-border: rgba(0, 0, 0, .23);

$sidebar-background: $secondary-theme-color-lighter;
$sidebar-label-selected-background: #e6e6e6;

$landing-background: whitesmoke;
$footer-background: whitesmoke;
$fab-background: white;
$tooltip-background: white;
$close-button-background: transparent;

$black-background-alpha2: rgba(0, 0, 0, .2);
$black-background-alpha1: rgba(0, 0, 0, .1); // Component colors

$review-form-border: rgb(179, 136, 255);
$review-text-field: rgba(0, 0, 0, .5);
$review-text-field-dark: rgb(255, 255, 255, .5);

$event-creation-form-border: rgb(244, 164, 96);
$event-request-form-border: #B9AD83;
$event-card-background: #ededed;
$event-card-background-dark: #262626;
$event-card-line-separator: white;
$submission-creation-form-border: #BA5658;

$sidebar-label-color: #434343;
$sidebar-label-color-dark: #D6D6D6;

$sidebar-divider-color: $sidebar-label-selected-background;

$admin-accent-color: white;
$admin-accent-color-dark: white;
$admin-accent-background-color: $accent-background;
$admin-accent-background-color-dark: rgb(0, 128, 255);

$row-topic-highlight-color: #e3e5eb;
$row-topic-highlight-color-dark: #262626;

// Colors for the top menu in admin
$navbar-admin-background: #F9F9F9;
$navbar-admin-background-dark: #262626;
$navbar-admin-menuitem-color: #434343;
$navbar-admin-menuitem-color-dark: #D6D6D6;
$navbar-admin-menuitem-hover-color: $admin-accent-color;
$navbar-admin-menuitem-hover-color-dark: $admin-accent-color-dark;
$navbar-admin-menuitem-hover-background: $admin-accent-background-color;
$navbar-admin-menuitem-hover-background-dark: $admin-accent-background-color-dark;
$navbar-admin-menuitem-selected-background: rgba(0, 0, 0, 0.09);
$navbar-admin-menuitem-selected-background-dark: rgba(255, 255, 255, .15);

// colors for the site menu in admin
$sidebar-admin-background: #F6F6F6;
$sidebar-admin-background-dark: #383838;
$sidebar-admin-menuitem-color: #434343;
$sidebar-admin-menuitem-color-dark: #F6F6F6;
$sidebar-admin-menuitem-hover-color: $admin-accent-color;
$sidebar-admin-menuitem-hover-color-dark: $admin-accent-color-dark;
$sidebar-admin-menuitem-hover-background: $admin-accent-background-color;
$sidebar-admin-menuitem-hover-background-dark: $admin-accent-background-color-dark;
$sidebar-admin-menuitem-selected-background: $navbar-admin-menuitem-selected-background;
$sidebar-admin-menuitem-selected-background-dark: $navbar-admin-menuitem-selected-background-dark;


$sidebar-label-selected-color: #3c4043;
$sidebar-label-selected-color-dark: white;

$sidebar-admin-label-selected-color: #3c4043;
$sidebar-admin-label-alt-selected-color: rgba(31, 31, 31, 0.8);

$navbar-admin-icon-color: #5a5a5a;
$navbar-icon-color: $sidebar-label-color;
$navbar-icon-color-clear: $secondary-theme-color-lighter;
$navbar-background-color: #F6F6F6;
$navbar-background-color-dark: #262626;

$navbar-events-icon-color: orange;
$navbar-events-icon-color-dark: orange;
$navbar-my-submissions-icon-color: blue;
$navbar-my-submissions-icon-color-dark: rgb(0, 98, 255);
$navbar-my-reviews-icon-color: green;
$navbar-my-reviews-icon-color-dark: rgb(0, 216, 0);
$navbar-my-tpcs-icon-color: red;
$navbar-my-tpcs-icon-color-dark: rgb(255, 64, 64);
$navbar-my-events-icon-color: purple;
$navbar-my-events-icon-color-dark: rgb(192, 22, 192);
$navbar-selected-menu-color: #e0e0e0;
$navbar-selected-menu-color-dark: #3c4043;
$search-bar-background: $sidebar-label-selected-background;
$landing-page-background-color: #fafafc;

$submission-files: rgb(54, 54, 54);
$inputs-landing-color: #b5b5b5;
$footer-version-color: black;
$fab-color: rgba(0, 0, 0, 0.87);
$tooltip-color: white;
$close-button-color: black; // Component Shadows
$close-button-color-dark: white;
$mat-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2),
0 2px 2px 0 rgba(0, 0, 0, .14),
0 1px 5px 0 rgba(0, 0, 0, .12);
$tooltip-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2),
0 2px 2px 0 rgba(0, 0, 0, .14),
0 1px 5px 0 rgba(0, 0, 0, .12);
// $inset-mat-shadow: inset 0 0 2px rgba(0, 0, 0, .12),
// inset 0 2px 4px rgba(0, 0, 0, .24); // Submission Status
$mat-card-title-background: #d1d1d1;
$inset-mat-shadow: 0px 0px 5px #bbbbbb; // Submission Status
$status-icon-color: white;
$close-button-color: black;
$slider-tick-background-color: rgba(0,0,0,1);
$fab-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
$tooltip-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
$slider-label-shadow: 0 1px 5px 1px rgba(0,0,0,.7);

// Track
$track-not-open-color: rgba(200, 200, 200, 0.5);
$track-closed-color: rgba(222,58,43,0.9);

// Errors
$error-invalid-color: #CC0029;
$notification-background-color: #7FC15C;
$notification-color: #fafafa;

$legend-color-less: #fb8c0033;
$legend-color-more: #b0bec580;
$legend-color-correct: #bbdefba6;
$legend-color-exact: #81c78466;

//Empty-state:
$empty-state-background: main-theme-color-light;
$empty-state-background-color-dark: #262626;

// Mat-card: Event
$mat-card-color: $sidebar-admin-menuitem-color;
$mat-card-color-dark: $sidebar-admin-menuitem-color-dark;
$mat-card-background-color: $sidebar-admin-background;
$mat-card-background-color-dark: $sidebar-admin-background-dark;
$mat-card-border-color: rgba(0, 0, 0, 0.1);
$mat-card-border-color-dark: rgba(255, 255, 255, 0.15);
$mat-card-header-color: $event-card-background;

$button-disabled-color: #999999;
$button-disabled-color-dark:#666666;

// Mat-card: Table
$table-background-color: #ededed;
$table-background-color-dark: #262626;
$table-header-background-color: #d1d1d1;
$table-header-background-color-dark: #4d4d4d;
$table-line-color: white;
$table-hover-border-color: rgba(0, 0, 0, .25);
$table-hover-border-color-dark: $admin-accent-background-color-dark;
$hyperlink-color: #0000EE;
$hyperlink-color-dark: $accent-background-dark;

//Mat-menu
$mat-menu-background-color: #F6F6F6;
$mat-menu-background-color-dark: #383838;

// Calendar
$calendar-header-background-color: $table-header-background-color;
$calendar-header-background-color-dark: $table-header-background-color-dark;
$calendar-background-color: $event-card-background;
$calendar-background-color-dark: $event-card-background-dark;

// Grayscale
$black-100: #fafafa;
$black-150: #f5f5f5;
$black-200: #ededed;
$black-300: #d1d1d1;
$black-400: #bbbbbb;
$black-500: #8b8d8f;
$black-600: #72767b;
$black-700: #4d5258;
$black-800: #393f44;
$black-900: #292e34;
$black-1000: #030303;

$white-1000: #ffffff;

// Link
$link-color: #1543C2;

// mat-tab
$mat-tab-label: #434343;
$mat-tab-label-dark: #D6D6D6;
$mat-tab-underline: rgba(0, 0, 0, 0.12);
$mat-tab-underline-dark: rgba(255, 255, 255, 0.3);


///
// Color definitions of submission and review status.
// Item format: "status: ( backgroundColor, linkColor )"
///
// Submission Status
//
$submission-status: (
  "pending": (#fab65c, $sidebar-label-color, #874d00),
  "active": (#FFFF55, $sidebar-label-color, #0000ff),
  "accepted": (#99FF99,$sidebar-label-color, #1c7530),
  "rejected": (#ffbdbd, $sidebar-label-color, #ff0000),
  "withdrawn": (#DDDDDD, $sidebar-label-color, #808080)
);

// Review Status
$review-status: (
  "assigned": (#FFFFFF, $sidebar-label-color, black),
  "notified": (#94C0FB, $sidebar-label-color, black),
  "confirmed": (#FFD860, $sidebar-label-color, black),
  "declined": (#C6C6C6, $sidebar-label-color, black),
  "reminded": (#FF8FA0, $sidebar-label-color, black),
  "late": (#fa5b5b, $sidebar-label-color, black),
  "delegated": (#ff89c9, $sidebar-label-color, black),
  "completed": (#63FCC8, $sidebar-label-color, black),
  "draft": (#AAAAAA, $sidebar-label-color, black)
  );

  // Review actions buttons
  $review-actions: (
    "confirm": #2636e6,
    "decline": #ce1414,
    "edit": #41ab0c,
    "delegate": #c93dde
  );

  $email-status: (
    "pending": (#fab65c, $sidebar-label-color, #874d00),
    "sent": (#99FF99,$sidebar-label-color, #1c7530),
    "rejected": (#ffbdbd, $sidebar-label-color, #ff0000),
  );

  $publication-status: (
    "ready": (#6da36f, $sidebar-label-color, black),
    "not-ready": (#f93826, $sidebar-label-color, black), // maybe: #ff5d6e
  );

// Span colors for submission ranking
$span-below-A-background-color: #75D775;
$span-above-B-background-color: #ff0000;
$span-between-A-and-B-background-color: rgb(240, 240, 74);

// Proceedings content types
$proceedings-content-types: (
  "submission": #99FF99,
  "file": #e78c84,
  "index": #aaa,
  "author-index": #aaa,
  "white-page": #fff
);

// Check list answer status
$checklist-answers-status: (
  "not-found": #ffbdbd,
  "unrelated": #FFFF55,
  "duplicated": #fab65c,
  "uncategorized": #d4d3d3,
  "invalid": #6d6d6d,
  "deleting": #888888,
  "ok": #99FF99
);

$publication-status-ready: #6da36f;
$publication-status-not-ready: #f93826;


// Interest Topics Colors for Bid
$interest-topic-background-color: #75D775;
$neutral-interest-topic-background-color: #e0e0e0;
$no-interest-topic-background-color: #f66d04;


//Default colors for dashboards
$default-first-sector-color: #ffc870;
$default-second-sector-color: #f7f7c6;
$default-third-sector-color: #c8e3c5;
$default-fourth-sector-color: #9cad9a;
$default-fifth-sector-color: #755858;
$default-sixth-sector-color: #abdf57;

//Chart grid color
$chart-grid-color: #00000033;
$chart-grid-color-dark: #ffffff33;
