.small-table {
    width: 100%;

    .mat-header-row {
        height: 28px;
        .mat-header-cell:first-of-type {
            padding-left: 0.5rem;
        }

        .mat-header-cell:last-of-type {
            padding-right: 0.5rem;
        }

        th {
            vertical-align: middle;
            padding: 0.5rem;
        }

        .mat-column-status, .mat-column-event, .mat-column-trackFileName, .mat-column-deadline, .mat-column-button {
            text-align: center;
        }

        .mat-column-status {
            width: 55px;
        }

        .mat-column-event {
            width: 115px;
            min-width: 85px;
        }

        .mat-column-deadline {
            width: 125px;
            min-width: 120px;
        }

        .mat-column-button {
            width: 220px;
        }
    }

    .mat-row {
        .mat-cell:first-of-type {
            padding-left: 0.5rem;
        }

        .mat-cell:last-of-type {
            padding-right: 0.5rem;
        }

        td {
            vertical-align: middle;
            padding: 0.5rem;
        }

        .mat-column-status, .mat-column-event, .mat-column-trackFileName, .mat-column-deadline, .mat-column-button {
            text-align: center;
        }

        td.mat-column-status {
            width: 55px;
        }

        td.mat-column-event {
            width: 115px;
            min-width: 85px;
        }

        td.mat-column-deadline {
            width: 125px;
            min-width: 120px;
        }

        td.mat-column-button {
            width: 220px;
        }
    }

    tr.upcoming .mat-column-deadline {
        background-color: RGBA(255, 165, 0, .5);
    }

    tr.late .mat-column-deadline {
        background-color: RGBA(165, 42, 42, .5);
    }

    &.legend {
        .upcoming {
            background-color: RGBA(255, 165, 0, .5);
        }

        .late {
            background-color: RGBA(165, 42, 42, .5);
        }
    }
}

table {
    &.simple-table{
        width: 100%;
  
        th {
          font-weight: 600;
        }
        
        th, td {
          vertical-align: middle;   
          border: 1px solid $table-line-color;
          font-size: .875rem;
          height: 30px;
          padding: 0 0.5rem;
    
          &.mat-header-cell:first-of-type, &.mat-cell:first-of-type, &.mat-header-cell:last-of-type, &.mat-cell:last-of-type {
            padding: 0 0.5rem;
          }

          .content-button {
            display: inline-flex;
            flex-direction: row;
            vertical-align: middle;
            align-items: center;
            flex-wrap: wrap;

            span {
                padding-right: 0.25rem;
            }

            &.justify-center {
                justify-content: center;
            }
          }
        }

        th:first-child, td:first-child {
          border-left-style: none;
        }

        th:last-child, td:last-child {
          border-right-style: none;
        }
    
        tr {
          height: 30px;
        }
    
    
        tfoot td {
          border-right-style: none;
          border-left-style: none;
          border-bottom-style: none;
    
          button {
            margin: 0.5rem;
          }
        }
    
        .flexed {
          width: 100%;
          vertical-align: middle;
    
          display: inline-flex;
          align-content: center;
          justify-content: center;
          align-items: center;
          // flex-direction: row;
    
          mat-icon {
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
          }
        }
    
        .no-line {
          border-right-style: none;
          border-bottom-style: none;
        }
    
        .no-right-line{
          border-right-style: none;
        }
    
        .no-bottom-line{
          border-bottom-style: none;
        }
    
        .no-padding {
          padding: 0;
        }

        .mat-icon-button {
            width: 25px;
            height: 25px;

            line-height: normal;

            display: inline-flex;
            flex-direction: row;
            vertical-align: middle;

            a {
                color:black;
            }
        }

        .mat-button-wrapper {
            display: inline-flex;
            flex-direction: row;
            vertical-align: middle;
            align-items: center;

            .mat-icon {
                display: flex;
                align-content: center;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

table {
    &.highlight-table {
        $table-hover-border-width: 2px;
        $table-row-border-radius: 5px;
        
        max-width: 100%;
        white-space: nowrap;
        border-collapse: separate;
        border-spacing: 0px 1px !important;
        
        th, td {
            text-align: center;
            vertical-align: middle;
            border-right: 1px solid;
            padding: 0 .5em !important;
        }
        
        th {
            font-size: 0.875rem;
            font-weight: 600;
            padding-top: 0;
            border:none !important;
        }

        tbody > tr, tfoot > tr {
            background-color: $table-background-color;
        }

        th:last-child {
            font-size: 0.8rem;
    
            .mat-icon {
                font-size: 0.9rem;
                vertical-align: baseline;
            }
        }

        td:first-child, td:last-child {
            font-size: .75rem;
            border: none !important;
        }

        td:first-child {
            padding-right: 12px !important;
        }

        td:nth-child(2) {
            border-left: $table-hover-border-width solid transparent;
            border-top-left-radius: $table-row-border-radius;
            border-bottom-left-radius: $table-row-border-radius;
        }

        td:nth-last-child(2) {
            border-right: $table-hover-border-width solid transparent;
            border-top-right-radius: $table-row-border-radius;
            border-bottom-right-radius: $table-row-border-radius;
        }

        tr {
            &:hover {
                td {
                    border-top: $table-hover-border-width solid;
                    border-bottom: $table-hover-border-width solid;
                }
                td:first-child, td:last-child{
                    border:none !important;
                }
                
                td:nth-child(2) {
                    border-left: $table-hover-border-width solid;
                }
                
                td:nth-last-child(2) {
                    border-right: $table-hover-border-width solid;
                }
                td.mat-footer-cell{
                    border:none !important;
                }
            }
        }

        tfoot {
            td {
                border: none !important;
            }
        }
    }

  .mat-sort-header-container {
    justify-content:center !important;
  }
}

// table {
//     &.simple-table{
//         width: 100%;
//         background: $table-background-color;
//         padding: 1rem 0;
//         line-height: 1.25;

//         th {
//           background: $table-header-background-color;
//           font-size: 0.80rem;
//           font-weight: 600;
//           text-align: center;
//           color: $sidebar-label-color;
//         }
    
//         th, td {
//           vertical-align: middle;
//           text-align: left;
    
//           border-color: $table-line-color;
//           border-style: solid;
//           border-width: thin;
    
    
//           padding-left: 1rem;
//           padding-right: 1rem;

//           height: 48px;
//           min-height: 48px;
    
//           &.mat-header-cell:first-of-type, &.mat-cell:first-of-type, &.mat-header-cell:last-of-type, &.mat-cell:last-of-type {
//             padding: 0 1rem;
//           }

//           .content-button {
//             display: inline-flex;
//             flex-direction: row;
//             vertical-align: middle;
//             align-items: center;
//             flex-wrap: wrap;

//             span {
//                 padding-right: 0.25rem;
//             }

//             &.justify-center {
//                 justify-content: center;
//             }
//           }
//         }

//         th:first-child, td:first-child {
//             border-left-style: none;
//         }

//         th:last-child, td:last-child {
//           border-right-style: none;
//         }
    
//         tbody td {
//           font-size: 0.75rem;
//         }
    
    
//         tfoot td {
//           border-right-style: none;
//           border-left-style: none;
//           border-bottom-style: none;
    
//           button {
//             margin: 0.5rem;
//           }
//         }
    
//         .flexed {
//           width: 100%;
//           vertical-align: middle;
    
//           display: inline-flex;
//           align-content: center;
//           justify-content: center;
//           align-items: center;
//           // flex-direction: row;
    
//           mat-icon {
//             display: flex;
//             align-content: center;
//             justify-content: center;
//             align-items: center;
//           }
//         }
    
//         .no-line {
//           border-right-style: none;
//           border-bottom-style: none;
//         }
    
//         .no-right-line{
//           border-right-style: none;
//         }
    
//         .no-bottom-line{
//           border-bottom-style: none;
//         }
    
//         .no-padding {
//           padding: 0;
//         }

//         .mat-icon-button {
//             width: 25px;
//             height: 25px;

//             line-height: normal;

//             display: inline-flex;
//             flex-direction: row;
//             vertical-align: middle;

//             a {
//                 color:black;
//             }
//         }

//         .mat-button-wrapper {
//             display: inline-flex;
//             flex-direction: row;
//             vertical-align: middle;
//             align-items: center;

//             .mat-icon {
//                 display: flex;
//                 align-content: center;
//                 justify-content: center;
//                 align-items: center;
//             }
//         }
//     }
// }
