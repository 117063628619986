  .legend, .caption {
    margin: 1em 0;
    font-size: 0.8rem;

    span {
      padding: 0.25em;
      margin: 0 0.25em;

      min-width: 90px;
      display: inline-block;
      text-align: center;
      font-weight: bold;
    }
  }
