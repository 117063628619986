.paginator-container {
  position: relative;
  padding: 10px 0;

  mat-paginator {
    position: absolute;
    top: 0;
    width: 100%;

    &.bottom {
      top: unset;
      bottom: 0;
    }

    .mat-paginator-outer-container {
      border-radius: 4px;
      margin-bottom: 10px;
      box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

      .mat-paginator-container {
        justify-content: space-between;
      }
    }
  }
}
