@import 'variables';

.show-all-submissions {
  .mat-card {
    margin-bottom: 2em;

    .paper-info {
      .main {
        > .columns {
          margin: -0.75rem -0.75rem 0 -0.75rem;

          .title-row {
            margin: 0;
            padding-bottom: 0;

            h2 {
              text-transform: uppercase;
            }
          }

          h2 {
            padding-top: 0;
          }
        }
      }
    }
  }

  .pagebreak {
    break-after: always;
  }
}

.show-all-submissions-container {
  background-color: $secondary-theme-color;
  padding: 1em;
}
