@import "~chartist/dist/scss/chartist.scss";

// Inside pie charts
.ct-chart-pie {
  .ct-label {
    font-size: 1.5rem;
  }
}

.ct-legend {
  position: relative;
  z-index: 10;

  li {
    position: relative;
    padding-left: 1.5em;
    padding-right: .5em;
    margin-bottom: 3px;

    cursor: pointer;
  }

  li:before {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 5.5px;
    left: 0;
    content: '';
    border: 3px solid transparent;
    border-radius: 2px;
  }

  li.inactive:before {
    background: transparent !important;
    
  }

  &.ct-legend-inside {
    position: absolute;
    right: 0;
  }

  @for $i from 0 to length($ct-series-colors) {
    .ct-series-#{$i}:before {
      background-color: nth($ct-series-colors, $i + 1);
      border-color: nth($ct-series-colors, $i + 1);
    }
  }
}

.ct-bar {
  stroke-width: 50px;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.8);
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.75rem;
  line-height: 1;
}

.ct-horizontal.ct-label.ct-end {
  &.ct-label-rotate-45 {
    transform: rotate(-45deg)
  }

  &.ct-label-rotate-45-end {
    position: relative;
    justify-content: flex-end;
    text-align: right;
    transform-origin: 100% 0;
    transform: translate(-100%) rotate(-45deg);
    white-space: nowrap;
  }
}

x-chartist svg {
  overflow: visible;
}
