.event-admin {  
  h3 .subtitle {
    font-size: 1.25rem;
    font-weight: 300;
  }

  h4 .subtitle {
    font-size: 1.2rem;
    font-weight: 300;
  }

  h5 .subtitle {
    font-size: 1.15rem;
    font-weight: 200;
  }

  h6 .subtitle {
    font-size: 1.1rem;
    font-weight: 200;
  }
}