@import 'variables';

$button-height: 36px;

.buttons {
  .is-divider {
    margin: 2rem 0;

    @media screen and (max-width: 599px) {
      margin: 1rem 0;
    }

    &:after {
      background-color: $landing-background;
      transform: translateY(-0.95rem);
      font-weight: bold;
    }
  }
}

a {
  outline: 0;
}

button {
  height: $button-height;
}

.close.button {
  position: absolute;
  top: 15px;
  right: 15px;

  width: 30px;
  height: 30px;
  line-height: 28px;
  font-size: 22px;

  cursor: pointer;
  text-align: center;
  z-index: 2;
}

.ripple-button.mat-raised-button {
  font-weight: 600;
  height: 34px;
  border-radius: 7px;

  .mat-icon {
    vertical-align: baseline !important;
  }

  &.selected {
    padding: 0 0.5rem;
    box-shadow: none;
    border: 2px solid transparent;
    &:hover {
      box-shadow: 1px 1px 5px $black-500;
    }
  }

  &:not(.selected) {
    padding: 0 0.5rem;
    background: transparent;
    box-shadow: none;
    border: 2px solid transparent;
    &:hover {
      box-shadow: 1px 1px 5px $black-500;
    }
    &:active {
      box-shadow: none;
      color: $sidebar-label-selected-color;
    }
  }

  &.mat-raised-button.cdk-program-focused .mat-button-focus-overlay {
    opacity: 0;
  }
}

.submissions, .reviews, .events, .myevents, .committees, .event-admin {
  .filters {
    display: flex;

    .toggle {
      font-family: $font-family;
      font-weight: 600;

      .mat-checkbox-label {
        margin-right: 8px;
        height: 100%;
        font-size: 13px;
      }

      .notify-button {
        color: red;
      }
    }
  }
}

.notify-button {
  color: red !important;
}

.file-icon-buttons {
  line-height: $file-icon-size;
  text-align: right;

  button {
    height: $file-icon-size;
    width: $file-icon-size;
    line-height: $file-icon-size;
  }

  .smaller-button, .smaller-button mat-icon {
    height: $file-icon-size-smaller;
    width: $file-icon-size-smaller;
    line-height: $file-icon-size-smaller;
    font-size: $file-icon-size-smaller-fontsize;
  }

  app-file-upload {
    margin-left: .5em;
    display: inline-block;
    line-height: $file-icon-size-smaller;
  }
}


// General refactor

button {
  &.mat-raised-button {
    display: inline-grid;
    vertical-align: middle;
    align-content: center;

    border-radius: 8px;

    .mat-icon {
      line-height: 1.4rem;
    }

    span {
      font-family: $font-family;
      line-height: 1rem;

      font-size: 0.875rem;
      font-weight: 500;

      vertical-align: middle;
      padding-left: 4px;

      &.mat-button-wrapper {
        padding: 0;

        display: inline-block;
        vertical-align: middle;
      }
    }

    &:hover {
      box-shadow: 1px 1px 7px $black-500;
    }
  }

  &.profile-button, &.mat-icon-button.profile-button {
    width: $button-height;
    height: $button-height;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    line-height: $button-height;
  }

  &[color].mat-raised-button span.mat-button-wrapper:not(:has(.mat-icon)), 
  &[color].mat-raised-button span.mat-button-wrapper:has(span) {
    color: #fff;
  }

  &.mat-raised-button.mat-button-disabled.mat-button-disabled[disabled="true"] {
    color: rgba(255, 255, 255, 0.3);
  }
}
