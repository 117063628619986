@import 'variables';
@import 'colors';

// Default background colors for submission and review.
$submission-bg: rgba(0, 0, 0, .1);
$review-bg: rgba(0, 0, 0, .1);

///
// Class definitions for submission and review status, considering optional background-fill and links.
///

.paperBackground { // Class for toggling background ON and OFF.
  span:not(.mat-button-wrapper), small {
    color: $black-1000;
  }

  a, .fa-file-pdf, .fa-upload {
    color: $hyperlink-color !important;

    &:hover {
      color: $hyperlink-color !important;
    }
  }

  .submission {
    background-color: $submission-bg;
  }

  .review {
    background-color: $review-bg;
  }

  border-radius: 5px;
  // padding: 4px 10px !important;
  color: $content-color;

  @mixin generate-status-background($status-list) {
    @each $status, $content in $status-list {
      &.#{$status}, &.#{$status}.paper-status {
//        a {
//          color: #{nth($content, 2)} !important;
//        }

        background-color: #{nth($content, 1)} !important;

        .mat-icon.status-icon {
          color: #{nth($content, 3)} !important;
        }
      }
    }
  }

  &.submission {
    @include generate-status-background($submission-status);
    @include generate-status-background($publication-status);
  }

  &.review {
    @include generate-status-background($review-status);
  }
}

.statusBackground {
  @mixin generate-status-background($status-list) {
      @each $status, $content in $status-list {
      &.#{$status}.icon-status-mobile {
        background-color: #{nth($content, 1)} !important;

        height: 19px;
        margin-bottom: 3px;
        margin-top: 2px;

        display: inline-flex;
        align-items: center;

        border-radius: 30px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, .3) ;

        padding-right: 12px;
        padding-left: 3px;

        border: 0px solid transparent;

        .borderBackground {
          &.borderColorBackground {
          }
        }


        .mat-icon.icon {
          color: #{nth($content, 3)} !important;
          font-size: 0.75rem;

          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        .string {
          // color: #{nth($content, 3)} !important;
          font-size: 0.75rem;
          // font-weight: bold;
        }
      }
    }
  }

  &.submission {
    @include generate-status-background($submission-status);
  }

  &.review {
    @include generate-status-background($review-status);
  }
}

// Submission Form Answers
.submission-form-item {
  p {
    .field-title {
      font-weight: bold;
    }

    .field-description {
      font-style: italic;
    }
  }
}
