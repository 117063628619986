@import 'colors';
@import 'variables';
@import 'mixins';

/* global styles */
@include setSelectorLightDarkProp('.title', '', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp('.subtitle', '', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(strong, '', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp('.close.button', '', color, $black-1000, $white-1000);
@include setSelectorLightDarkProp('', '.mat-checkbox-checkmark-path', stroke, '', $white-1000 !important);
@include setSelectorLightDarkProp('', '.main-header .main-title', color, $navbar-admin-menuitem-color, $navbar-admin-menuitem-color-dark);

/* simple-table */
@include setSelectorLightDarkProp('table.simple-table', '', background-color, $table-background-color, $table-background-color-dark);
@include setSelectorLightDarkProp('table.simple-table', th, background-color, $table-header-background-color, $table-header-background-color-dark);
@include setSelectorLightDarkProp('table.simple-table', th, color, $content-color, $content-color-dark);

/* table */
@include setSelectorLightDarkProp('table.highlight-table', '', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp('table.highlight-table', 'td:not(:first-child, :last-child, .mat-footer-cell)', background-color, $table-background-color, $table-background-color-dark);
@include setSelectorLightDarkProp('table.highlight-table', 'td:not(:first-child, :last-child, .mat-footer-cell)', border-color, $table-background-color, $table-background-color-dark);
@include setSelectorLightDarkProp('table.highlight-table', 'td:not(:first-child, :last-child, .mat-footer-cell)', border-right-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp('table.highlight-table', 'tr:hover td', border-top-color, $table-hover-border-color, $table-hover-border-color-dark);
@include setSelectorLightDarkProp('table.highlight-table', 'tr:hover td', border-bottom-color, $table-hover-border-color, $table-hover-border-color-dark);
@include setSelectorLightDarkProp('table.highlight-table', 'tr:hover td:first-child', border, none, none);
@include setSelectorLightDarkProp('table.highlight-table', 'tr:hover td:last-child', border, none, none);
@include setSelectorLightDarkProp('table.highlight-table', 'tr:hover td:nth-child(2)', border-left-color, $table-hover-border-color, $table-hover-border-color-dark);
@include setSelectorLightDarkProp('table.highlight-table', 'tr:hover td:nth-last-child(2)', border-right-color, $table-hover-border-color, $table-hover-border-color-dark);
@include setSelectorLightDarkProp('table.highlight-table', 'tr:hover td.mat-footer-cell', border, none, none);

/* <a> */
@include setSelectorLightDarkProp(a, '', color, $hyperlink-color, $hyperlink-color-dark);
@include setSelectorLightDarkProp('a:hover', '', color, $hyperlink-color, $hyperlink-color-dark);

/* <button> */
@include setSelectorLightDarkProp('button:disabled', '', color, $button-disabled-color, $button-disabled-color-dark);
@include setSelectorLightDarkProp('button.filter.ripple-button:hover', '', background-color, $navbar-selected-menu-color, $navbar-selected-menu-color-dark);
@include setSelectorLightDarkProp('button.filter.ripple-button:hover', '', box-shadow, '', 1px 1px 9px black);

/* mat-accordion */
@include setSelectorLightDarkProp('.mat-accordion', '.mat-expansion-panel', background-color, $event-card-background, $event-card-background-dark);

/* mat-form-field */
@include setSelectorLightDarkProp('.mat-form-field-infix', '', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp('.mat-form-field-appearance-outline .mat-form-field-outline', '', color, $black-1000, $white-1000);
@include setSelectorLightDarkProp('.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick', '', color, $black-1000, $white-1000);

/* mat-divider */
@include setSelectorLightDarkProp('.mat-divider', '', border-bottom, 1px solid white, 1px solid rgba(255, 255, 255, 0.12));
@include setSelectorLightDarkProp('.mat-divider', '', border-top, '', 1px solid black);

/* app-admin */
@include setSelectorLightDarkProp(app-admin, '.admin', background-color, $admin-background, $admin-background-dark);

/* app-admin-navbar */
@include setSelectorLightDarkProp(app-admin-navbar, '.admin-navbar nav', background-color, $admin-background, $admin-background-dark);
@include setSelectorLightDarkProp(app-admin-navbar, '.logo img', -webkit-filter, '', grayscale(1) invert(1));
@include setSelectorLightDarkProp(app-admin-navbar, '.logo img', filter, '', grayscale(1) invert(1));

/* app-admin-event-sidebar */
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.label', color, $sidebar-label-color, $sidebar-label-color-dark);
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.mat-icon', color, $sidebar-label-color, $sidebar-label-color-dark);
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.mat-sidenav-content', background-color, $admin-background, $admin-background-dark);
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.sidebar .mat-sidenav-container.content mat-sidenav', background-color, $sidebar-admin-background, $sidebar-admin-background-dark);
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.sidebar .mat-sidenav-container mat-sidenav .mat-list-item:hover', background-color, $sidebar-admin-menuitem-hover-background, $sidebar-admin-menuitem-hover-background-dark);
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.sidebar .mat-sidenav-container mat-sidenav .mat-list-item:hover', color, $sidebar-admin-menuitem-hover-color, $sidebar-admin-menuitem-hover-color-dark);
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.sidebar .mat-sidenav-container mat-sidenav .list-item-active', background-color, $sidebar-admin-menuitem-selected-background, $sidebar-admin-menuitem-selected-background-dark);
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.logo img', -webkit-filter, grayscale(1) invert(1), '');
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.logo img', filter, grayscale(1) invert(1), '');
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.navbar', background-color, $navbar-admin-background, $navbar-admin-background-dark);
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.navbar', box-shadow, 0px 2px 5px rgba(0, 0, 0, .2), 0px 2px 9px rgba(0, 0, 0, .5));
@include setSelectorLightDarkProp(app-admin-event-sidebar, '.navbar', border-bottom-color, #d3d3d3, #000);

/* app-event-wizard */
@include setSelectorLightDarkProp(app-event-wizard, '.task-text', color, black, white);

/* mat-card */
@include setSelectorLightDarkProp(mat-card, 'mat-card-title', color, $sidebar-label-color, $white-1000);
@include setSelectorLightDarkProp('mat-card.base-card', '', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp('mat-card.base-card', '', color, $event-card-background-dark, $event-card-background);
@include setSelectorLightDarkProp('mat-card.event', "", background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp('mat-card.event .event-parent span', '', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp('mat-card.event .event-parent a', '', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp('mat-card.event:hover', "", border, 2px solid rgba(0, 0, 0, 0.25), 2px solid rgba(255, 255, 255, 0.2));
@include setSelectorLightDarkProp('mat-card.event:not([class*=mat-elevation-z])', "", box-shadow, none, none);
@include setSelectorLightDarkProp('.mat-card-header', '', background-color, $table-background-color, $table-background-color-dark);
@include setSelectorLightDarkProp('.mat-card-header', '', color, $sidebar-label-color, $sidebar-label-color-dark);
@include setSelectorLightDarkProp('mat-card.admin-event', '', background-color, $mat-card-background-color, $mat-card-background-color-dark);
@include setSelectorLightDarkProp('mat-card.admin-event', '', border-color, $mat-card-border-color, $mat-card-border-color-dark);

/* app-tracks */
@include setSelectorLightDarkProp(app-tracks, '.tracks-card mat-card.track', background-color, #e5e5e5, lighten($navbar-background-color-dark, 5));
@include setSelectorLightDarkProp(app-tracks, '.tracks-card mat-card.track .mat-card-title', color, $black-1000, $white-1000);

/* app-sidebar-item */
@include setSelectorLightDarkProp(app-sidebar-item, '.list-item.button-events .mat-icon', color, $navbar-events-icon-color, $navbar-events-icon-color-dark);
@include setSelectorLightDarkProp(app-sidebar-item, '.list-item.button-my-submissions .mat-icon', color, $navbar-my-submissions-icon-color, $navbar-my-submissions-icon-color-dark);
@include setSelectorLightDarkProp(app-sidebar-item, '.list-item.button-my-reviews .mat-icon', color, $navbar-my-reviews-icon-color, $navbar-my-reviews-icon-color-dark);
@include setSelectorLightDarkProp(app-sidebar-item, '.list-item.button-tpcs .mat-icon', color, $navbar-my-tpcs-icon-color, $navbar-my-tpcs-icon-color-dark);
@include setSelectorLightDarkProp(app-sidebar-item, '.list-item.button-chairings .mat-icon', color, $navbar-my-events-icon-color, $navbar-my-events-icon-color-dark);
@include setSelectorLightDarkProp(app-sidebar-item, '.list-item.any-other-button .mat-icon', color, $sidebar-label-color, $navbar-events-icon-color-dark);
@include setSelectorLightDarkProp(app-sidebar-item, '.label-full', color, $sidebar-label-selected-color, $sidebar-label-selected-color-dark);
@include setSelectorLightDarkProp('.sidebar-item', '.jems-toolbar-button:not(.selected):hover', background-color, '', $navbar-selected-menu-color-dark);
@include setSelectorLightDarkProp('.sidebar-item', '.jems-toolbar-button:not(.selected):hover', box-shadow, '', 1px 1px 9px black !important);
@include setSelectorLightDarkProp('.sidebar-item', '.jems-toolbar-button.selected', background-color, $navbar-selected-menu-color !important, $navbar-selected-menu-color-dark !important);
@include setSelectorLightDarkProp('.sidebar-item', '.jems-toolbar-button.selected:hover', box-shadow, '', 1px 1px 9px black !important);
@include setSelectorLightDarkProp('.sidebar-item', '.jems-toolbar-button .label-short', color, $sidebar-label-selected-color, $sidebar-label-selected-color-dark);

/* app-sidebar-item-admin */
@include setSelectorLightDarkProp(app-sidebar-item-admin, '.sidebar-item-admin .jems-chair-toolbar-button:hover .mat-icon', color, $navbar-admin-menuitem-hover-color, $navbar-admin-menuitem-hover-color-dark);
@include setSelectorLightDarkProp(app-sidebar-item-admin, '.sidebar-item-admin .jems-chair-toolbar-button:hover .mat-icon', background-color, $navbar-admin-menuitem-hover-background, $navbar-admin-menuitem-hover-background-dark);
@include setSelectorLightDarkProp(app-sidebar-item-admin, '.sidebar-item-admin .jems-chair-toolbar-button.selected:not(:hover) .mat-icon', background-color, $navbar-admin-menuitem-selected-background, $navbar-admin-menuitem-selected-background-dark);

/* app-event */
@include setSelectorLightDarkProp(app-event, '.name a', color, $sidebar-label-color, $black-400);
@include setSelectorLightDarkProp(app-event, '.sub-event .label', color, $sidebar-label-color, $white-1000);

/* app-event-view */
@include setSelectorLightDarkProp(app-event-view, '.event.card .information .name', color, $sidebar-label-color, $black-400);
@include setSelectorLightDarkProp(app-event-view, '.event.card .information .sub-event .name a', color, $sidebar-label-color, $black-400);
@include setSelectorLightDarkProp(app-event-view, '.event.card .information .label', color, $sidebar-label-color, $white-1000);

/* app-event-user-view */
$table-hover-border-width: 2px;
$table-row-border-radius: 5px;
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view mat-card .mat-card-header', color, $black-1000, $white-1000);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view mat-card .mat-card-header', background-color, $white-1000, $black-1000);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view table', background-color, $white-1000, $black-1000);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view table th', background-color, $white-1000, $black-1000);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view table th', color, $black-1000, $white-1000);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view table tr td:not(._no-border)', border-right, 1px solid white !important, 1px solid black !important);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view table tr:hover td:first-child:not(._no-border)', border-left, $table-hover-border-width solid rgba(0, 0, 0, .25) !important, $table-hover-border-width solid $admin-accent-background-color-dark !important);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view table tr:hover td:last-child:not(._no-border)', border-right, $table-hover-border-width solid rgba(0, 0, 0, .25) !important, $table-hover-border-width solid $admin-accent-background-color-dark !important);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view table tr:hover td:not(._no-border)', border-top, $table-hover-border-width solid rgba(0, 0, 0, .25) !important, $table-hover-border-width solid $admin-accent-background-color-dark !important);
@include setSelectorLightDarkProp(app-event-user-view, '.event-user-view table tr:hover td:not(._no-border)', border-bottom, $table-hover-border-width solid rgba(0, 0, 0, .25) !important, $table-hover-border-width solid $admin-accent-background-color-dark !important);

/* app-user-search */
@include setSelectorLightDarkProp(app-user-search, '.user-search .user-box', background-color, $content-background, $content-background-dark);

/* app-topics */
@include setSelectorLightDarkProp(app-topics, '.topics .mat-chip', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-topics, '.topics .mat-chip', color, $content-color, $content-color-dark);

/* app-event-topics */
@include setSelectorLightDarkProp(app-event-topics, '.event-topics .subtitle', color, $sidebar-label-color, $sidebar-label-color-dark);

/* app-user-event-topics */
@include setSelectorLightDarkProp(app-user-event-topics, '.event-topics .topics-table .row-highlight', background-color, $row-topic-highlight-color, $row-topic-highlight-color-dark);

/* app-event-send-email */
@include setSelectorLightDarkProp(app-event-send-email, '.event-send-email mat-card-content', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-event-send-email, '.event-send-email mat-card-content', color, $content-color, $content-color-dark);

/* app-event-settings-email */
@include setSelectorLightDarkProp(app-event-settings-email, '.cochair-notification table', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-event-settings-email, '.cochair-notification table th', background-color, $table-header-background-color, $table-header-background-color-dark);
@include setSelectorLightDarkProp(app-event-settings-email, '.cochair-notification table th', color, $content-color, $content-color-dark);

/* app-navbar */
@include setSelectorLightDarkProp(app-navbar, '.logo img', -webkit-filter, '', grayscale(1) invert(1));
@include setSelectorLightDarkProp(app-navbar, '.logo img', filter, '', grayscale(1) invert(1));
@include setSelectorLightDarkProp(app-navbar, '.navbar', box-shadow, '', 0px 2px 9px rgba(0, 0, 0, .5));

/* app-avatar-profile */
@include setSelectorLightDarkProp('.mat-menu-panel', '', background-color, $mat-menu-background-color, $mat-menu-background-color-dark);
@include setSelectorLightDarkProp('.mat-menu-panel', '', box-shadow, '', 0px 2px 10px 1px black);
@include setSelectorLightDarkProp('.mat-menu-content', '.mat-menu-item .mat-icon, .mat-menu-item', color, $sidebar-label-color, $sidebar-label-color-dark);
@include setSelectorLightDarkProp('button.mat-menu-item:hover:not([disabled])', '', background-color, $admin-accent-background-color, $admin-accent-background-color-dark);
@include setSelectorLightDarkProp('button.mat-menu-item:hover', '', color, $admin-accent-color, $admin-accent-color-dark);
@include setSelectorLightDarkProp('button.mat-menu-item:hover', '.mat-icon', color, $admin-accent-color, $admin-accent-color-dark);
@include setSelectorLightDarkProp('.mat-menu-content .container-theme .highlight-button', '', background-color, $navbar-selected-menu-color, rgba(255, 255, 255, 0.15));

/* app-fixed-top-progress-bar */
@include setSelectorLightDarkProp(app-fixed-top-progress-bar, '.fixed-top-progress-bar', background-color, $navbar-background-color, $navbar-background-color-dark);

/* app-home */
@include setSelectorLightDarkProp(app-home, '.home', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-home, '.home', color, $content-background-dark, $content-background);
@include setSelectorLightDarkProp(app-home, '.home .page-content', background-color, $content-background, $content-background-dark);

/* app-paper-info */
@include setSelectorLightDarkProp(app-paper-info, '.paper-info a', color, $content-color, $content-color-dark);

/* app-paper-card */
@include setSelectorLightDarkProp(app-paper-card, '.paper-card mat-card', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-paper-card, '.paper-card mat-card .horizontal-divider', border-color, $white-1000, $black-1000);
@include setSelectorLightDarkProp(app-paper-card, '.paper-card mat-card .vertical-divider', border-color, $white-1000, $black-1000);
@include setSelectorLightDarkProp(app-paper-card, '.paper-card mat-card.compact:hover', border, 2px solid rgba(0, 0, 0, .25), 2px solid rgba(255, 255, 255, .25));
@include setSelectorLightDarkProp(app-paper-card, '.paper-card .card.mat-card.compact span.label-mobile', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-paper-card, '.paper-card .card.mat-card.compact .assigned-by-mobile .label-mobile span', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-paper-card, '.paper-card .card.mat-card.compact .horizontal-divider.mobile', border-top, '', 1px solid black);
@include setSelectorLightDarkProp(app-paper-card, '.paper-card .card.mat-card.compact .horizontal-divider.mobile', border-bottom, 1px solid white, 1px solid rgba(255, 255, 255, 0.12));

/* app-dialog-timezone */
@include setSelectorLightDarkProp('.timeZonePanel', table thead tr th, background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp('.timeZonePanel', table thead tr th, border-bottom-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp('.timeZonePanel', 'table thead tr th:first-child', border-right-color, $white-1000, $black-1000);
@include setSelectorLightDarkProp('.timeZonePanel', table tbody tr td, background-color, $white-1000, rgb(24, 24, 24));
@include setSelectorLightDarkProp('.timeZonePanel', table tbody tr td, border-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp('.timeZonePanel', 'table tbody tr td:first-child', border-right-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp('.timeZonePanel', 'table tbody tr:hover td', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp('.timeZonePanel', 'table tbody tr:hover td', border-right-color, $white-1000, $black-1000);

/* app-landing */
@include setSelectorLightDarkProp(app-landing, '.landing .content-container', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-landing, '.landing .content-container .logo.image', filter, '', invert(1));
@include setSelectorLightDarkProp(app-landing, '.landing .content-container i', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-landing, '.landing .language-container', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-landing, '.landing .language-container', background-color, $event-card-background, $event-card-background-dark);

/* app-events */
@include setSelectorLightDarkProp(app-events, '.events .main-title i', color, $navbar-events-icon-color, $navbar-events-icon-color-dark);

/* app-submissions */
@include setSelectorLightDarkProp(app-submissions, '.submissions .main-title i', color, $navbar-my-submissions-icon-color, $navbar-my-submissions-icon-color-dark);

/* app-reviews */
@include setSelectorLightDarkProp(app-reviews, '.reviews .main-title i', color, $navbar-my-reviews-icon-color, $navbar-my-reviews-icon-color-dark);

/* app-paper-review-list */
@include setSelectorLightDarkProp(app-paper-review-list, '.paper-review-list .table-container .table .review td', border-color, $admin-background, $admin-background-dark);

/* app-committees */
@include setSelectorLightDarkProp(app-committees, '.committees .main-title i', color, $navbar-my-tpcs-icon-color, $navbar-my-tpcs-icon-color-dark);

/* app-my-events */
@include setSelectorLightDarkProp(app-my-events, '.myevents .main-title i', color, $navbar-my-events-icon-color, $navbar-my-events-icon-color-dark);

/* app-submissions-list-table */
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table td:not(.roundBorderBegin, :nth-last-child(3))', border-right, 1px solid white, 1px solid black);
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table th', color, $content-lightcolor, $content-lightcolor-dark);
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table td:first-child', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table td:last-child', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table td:last-child', color, $content-lightcolor, $content-lightcolor-dark);
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table tr:hover td', border-color, $table-hover-border-color !important, $table-hover-border-color-dark !important);
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table tr:hover td', border-right-color, $white-1000 !important, $black-1000 !important);
@include setSelectorLightDarkProp(app-submissions-list-table, '.mat-card table.mat-table tr:hover td:nth-last-child(2)', border-right-color, $table-hover-border-color !important, $table-hover-border-color-dark !important);

/* app-submissions-list-table-v2 */
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table td:first-child', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table td:last-child', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table td:not(.last-column)', border-right, 1px solid $content-background, 1px solid $content-background-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table tr:hover td:not(:first-child):not(:last-child)', border-top-color, $table-hover-border-color, $table-hover-border-color-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table tr:hover td:not(:first-child):not(:last-child)', border-bottom-color, $table-hover-border-color, $table-hover-border-color-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table tr:hover td.first-column', border-left, $table-hover-border-width solid $table-hover-border-color !important, $table-hover-border-width solid $table-hover-border-color-dark !important);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table tr:hover td.last-column', border-right, $table-hover-border-width solid $table-hover-border-color !important, $table-hover-border-width solid $table-hover-border-color-dark !important);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table tr.hovered td:not(:first-child):not(:last-child)', border-top-color, $table-hover-border-color, $table-hover-border-color-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table tr.hovered td:not(:first-child):not(:last-child)', border-bottom-color, $table-hover-border-color, $table-hover-border-color-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table tr.hovered td.first-column', border-left, $table-hover-border-width solid $table-hover-border-color !important, $table-hover-border-width solid $table-hover-border-color-dark !important);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table tr.hovered td.last-column', border-right, $table-hover-border-width solid $table-hover-border-color !important, $table-hover-border-width solid $table-hover-border-color-dark !important);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table td span.subtitle-text', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table td.position', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table th .mark-all-button', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-submissions-list-table-v2, '.mat-card .submission-table.mat-table td.select button', background-color, $content-background, $content-background-dark);

/* app-submissions-field-filter-v2 */
@include setSelectorLightDarkProp(app-submissions-field-filter-v2, '.submissions-fields-filter-table-V2 .mat-expansion-panel', background-color, $mat-card-background-color, $mat-card-background-color-dark);
@include setSelectorLightDarkProp(app-submissions-field-filter-v2, '.submissions-fields-filter-table-V2 .mat-expansion-panel', border-color, $mat-card-border-color, $mat-card-border-color-dark);

/* app-submissions-list-filter */
@include setSelectorLightDarkProp(app-submissions-list-filter, '.submissions-list-filter .fields', background-color, '', rgba(0, 0, 0, .75));

/* app-file-rules-card */
@include setSelectorLightDarkProp(app-file-rules-card, '.file-card .top-file-card', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-file-rules-card, '.file-card .uploading', background-color, $admin-accent-background-color, $admin-accent-background-color-dark);
@include setSelectorLightDarkProp(app-file-rules-card, '.file-card .notUploading', background-color, $admin-background, $admin-background-dark);
@include setSelectorLightDarkProp(app-file-rules-card, '.file-card .detail-columns .due-date', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-file-rules-card, '.file-card .detail-columns .disabled .mat-icon', color, $button-disabled-color, $button-disabled-color-dark);
@include setSelectorLightDarkProp(app-file-rules-card, '.file-card .detail-columns .download-column .button-download-file:not(.disabled) .mat-icon', color, $accent-background, $accent-background-dark);
@include setSelectorLightDarkProp(app-file-rules-card, '.file-card .detail-columns .info-column .button-rule-info .mat-icon', color, $accent-background, $accent-background-dark);

/* app-file-upload */
@include setSelectorLightDarkProp(app-file-upload, '.file-upload .file-upload-icon .mat-icon', color, $accent-background, $accent-background-dark);

/* app-custom-form-text-question */
@include setSelectorLightDarkProp(app-custom-form-text-question, '.custom-text-question textarea', background-color, $white-1000, $black-1000);

/* app-export-submissions */
@include setSelectorLightDarkProp(app-export-submissions, '.export-submissions .section .name a', color, $content-color, $content-color-dark);

/* app-event-publication */
@include setSelectorLightDarkProp(app-event-publication, '.publication-controls .config-form .custom-control', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-event-publication, '.publication-controls .mat-card', background-color, $content-background, $content-background-dark);

/* app-publication-fixed-control */
@include setSelectorLightDarkProp(app-publication-fixed-control, '.fixed-control .mat-card table', background-color, $content-background, $content-background-dark);
@include setSelectorLightDarkProp(app-publication-fixed-control, '.fixed-control .mat-card table td', border-right-color, $content-background !important, $content-background-dark !important);
@include setSelectorLightDarkProp(app-publication-fixed-control, '.fixed-control .mat-card table th', border-right-color, $content-background !important, $content-background-dark !important);
@include setSelectorLightDarkProp(app-publication-fixed-control, '.fixed-control .mat-card table th', color, $content-lightcolor, $content-lightcolor-dark);

/* app-proceeding-step */
@include setSelectorLightDarkProp(app-proceeding-step, '.content .content-list h3', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-proceeding-step, '.content .not-included', border, 1px solid black, 1px solid white);
@include setSelectorLightDarkProp(app-proceeding-step, '.content .included', border, 1px solid black, 1px solid white);
@include setSelectorLightDarkProp(app-proceeding-step, '.content .track', border, 1px solid black, 1px solid white);

/* app-proceeding-index-item */
@include setSelectorLightDarkProp(app-proceeding-index-item, '.item .mat-expansion-panel', box-shadow, '', 0px 2px 10px 1px black);

/* app-proceeding-author-index-item */
@include setSelectorLightDarkProp(app-proceeding-author-index-item, '.item .mat-expansion-panel', box-shadow, '', 0px 2px 10px 1px black);

/* app-calendar-view */
@include setSelectorLightDarkProp(app-calendar-view, '.container-view-date', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-calendar-view, 'mwl-calendar-week-view', color, '', $content-color-dark);
@include setSelectorLightDarkProp(app-calendar-view, '.cal-day-headers', background-color, '', $calendar-header-background-color-dark);
@include setSelectorLightDarkProp(app-calendar-view, '.cal-week-view, .cal-time, .cal-hour-segment', background-color, '', $calendar-background-color-dark);
@include setSelectorLightDarkProp(app-calendar-view, '.cal-week-view .cal-day-headers .cal-header:hover', background-color, rgba(0,0,0,.3), rgba(255,255,255,.3));
@include setSelectorLightDarkProp(app-calendar-view, '.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover ', background-color, rgba(0,0,0,.3), rgba(255,255,255,.3));

/* app-chip-checkbox */
@include setSelectorLightDarkProp(app-chip-checkbox, '.chip-checkbox label div:not(.checked)', color, '', #868686);
@include setSelectorLightDarkProp(app-chip-checkbox, '.chip-checkbox label .mat-icon:not(.checked)', color, '', #868686);

/* app-dashboard-settings */
@include setSelectorLightDarkProp(app-dashboard-settings, '.dataset-menu', background-color, $event-card-background, $event-card-background-dark);
@include setSelectorLightDarkProp(app-dashboard-settings, '.dataset-menu .mat-icon', color, $accent-background, $accent-background-dark);

/* app-chart-component */
@include setSelectorLightDarkProp(app-chart-component, '.chart-container', background-color, $mat-card-background-color, $mat-card-background-color-dark);
@include setSelectorLightDarkProp(app-chart-component, '.chart-container', border-color, $mat-card-border-color, $mat-card-border-color-dark);
@include setSelectorLightDarkProp(app-chart-component, '.chart-container .ct-axis-title', fill, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-chart-component, '.chart-container .ct-label', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-chart-component, '.chart-container .legend-title', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-chart-component, '.chart-container .ct-grid', stroke, $chart-grid-color, $chart-grid-color-dark);
@include setSelectorLightDarkProp(app-chart-component, '.ct-legend li', color, $content-color, $content-color-dark);


/* app-chart-component-v2 */
@include setSelectorLightDarkProp(app-chart-component-v2, '.chart-container-v2 .chart text', fill, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-chart-component-v2, '.chart-container-v2 .legend-label .legend-label-text', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-chart-component-v2, '.chart-v2', background-color, $mat-card-background-color, $mat-card-background-color-dark);


/* app-math-captcha */
@include setSelectorLightDarkProp(app-math-captcha, '.container', color, $content-color, $content-color-dark);
@include setSelectorLightDarkProp(app-math-captcha, '#buttonInput', background-color, $accent-background, $accent-background-dark);
@include setSelectorLightDarkProp(app-math-captcha, '.cls-1', fill, $accent-background, $accent-background-dark);

/* app-proceeding-step */
@include setSelectorLightDarkProp (app-proceeding-step, '.sections-list .hyperlink', color, $hyperlink-color, $hyperlink-color-dark);
@include setSelectorLightDarkProp (app-proceeding-step, '.sections-list .no-hyperlink', color, $sidebar-label-color !important, $sidebar-label-color-dark !important);
@include setSelectorLightDarkProp (app-proceeding-step, '.sections-list', border-color, $sidebar-label-color, $sidebar-label-color-dark);