@import 'variables';

.mat-tooltip {
  &.cardTooltip {
    box-shadow: $tooltip-shadow;

    background: $tooltip-background;
    color: $tooltip-color;

    padding: 12px;
    font-size: .8rem;
  }
}
