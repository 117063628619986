@import 'colors'; // sizes
@import 'components/header';

$max-base-container-width: 1100px;

$desktop-width-md: 1450px;
$desktop-width-sm: 1216px;
$tablet-breakup-width: 768px;
$mobile-width: 486px;
$screen-iPhoneSE-portrait-width: 320px;
$screen-iPhone8-portrait-width: 375px;
$screen-iPhone8plus-portrait-width: 414px;
$screen-iPadMini-portrait-width: 768px;
$screen-iPadMini-landscape-width: 1024px;

// Screen width per interface
$screen-main-portal-short: 890px;


$min-screen-width: 320px;
$navbar-mobile-break: 600px;

$event-title-bar: 0px;
$navbar-height: 55px;
$proxy-bar-height: 38px;
$proxy-bar-margin-size: 45px;
$sidenav-width: 200px;
$sidenav-mobile-width: 250px;
$content-width: $sidenav-width + 100px;
$content-width-admin: 0px;
$sidenav-mini-width: 45px; // z-indexes
$sidenav-admin-mini-width: 79px;
$sidebar-content-z-index: 10;
$sidebar-z-index: 20;
$navbar-z-index: 31;
$progress-bar-z-index: 32;
$progress-bar-height: 2px;
$sidebar-backdrop-z-index: 35;
$sidebar-mobile-z-index: 40;
$navbar-icon-size: 72px;

// File icon size variables.
$file-icon-size: 30px;
$file-icon-size-smaller: $file-icon-size * 0.6;
$file-icon-size-smaller-fontsize: 0.8rem;


// Main layout paddings or margin
$lateral-padding-content-desktop-md: 1.3rem;
$lateral-padding-content-desktop-sm: 0.5rem;
$lateral-padding-content-tablet: 1rem;
$lateral-padding-content-mobile: 0.5rem;

// size for avatar
$avatar-size: 36px;
