app-sidebar {
  mat-sidenav {
    .mat-list .mat-list-item .mat-list-item-content {
      transition: padding 0.2s;
    }

    .mat-list button.mat-icon-button {
      transition: margin 0.2s;
      color: $navbar-icon-color;
    }
  }

  &.mini {
    mat-sidenav {
      .mat-list .mat-list-item .mat-list-item-content {
        transition: padding 0.3s;
        padding: 0 7px;
      }
    }
  }

  .mat-sidenav .mat-icon svg {
    width: 16px;
  }
}

app-admin-event-sidebar {
  mat-sidenav {
    .mat-list {
      .mat-list-item .mat-list-item-content {
        margin: 0px;
        padding: 4px;
      }
    }

    &.menu {
      .mat-drawer-inner-container {
        display: grid;
        overflow: hidden;

        mat-list {
          &.bottom-aligned {
            align-self: flex-end;
            align-self: end;
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }
}
