@import 'variables';

.mat-dialog-container {
  border-radius: 0.875rem !important;
  border: 1px solid transparent;
  padding: 15px !important;
  text-align: center;

  .modalWindow {
    width: 250px;

    h1 {
      font-size: 0.875rem;
      font-weight: 600;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 0.813rem;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .logoContainer {
      display: flex;
      justify-content: center;
      font-size: 1.8rem;
      margin: 1.7rem 0 2rem 0;

      .logo {
        box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.2);
        padding: 5px 15px;
        border-radius: 7px;
        // background-color: Gainsboro;
        // color: black;
      }
    }

    button {
      margin: 0.25rem;
      width: 112px;
    }

  }

  .mat-form-field-flex {
    // background-color: white;
    // border: 0px none white;
    .mat-form-field-appearance-outline {
      background-color: white;
      box-shadow: inset 0 1px 3px 1px LightGray;
    }
  }

  .mat-form-field-infix {
    // border-top: 0px none white;
    border: 0px none white;
  }
}

.mat-form-field {
  width: 100%;
  line-height: 1.5;

  .mat-form-field-wrapper {
    .mat-icon {
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
}

app-search-button {
  .mat-search_field {
    input {
      padding-left: 8px;
      padding-right: 36px;
    }

    .mat-form-field-underline {
      height: 3px;

      background-color: $navbar-icon-color;
      border-radius: 1.5px;
    }

    &.mat-form-field-should-float {
      label.mat-form-field-label {
        opacity: 0;
        transition: all .4s ease-in;

        transform: translateY(-1.28125em) scale(1.5) perspective(100px) translateZ(0.001px);
      }
    }

    label.mat-form-field-label {
      opacity: 1;
      transition: all .2s ease-in-out;

      font-size: 1rem;
      top: 2rem;

      text-align: left;
      width: 80%;
      padding-left: 3rem;
    }
  }
}

.topics-table {
  .mat-radio-label {
    width: 20px;
  }
}

// adds breaks to the mat-checkboxes with long labels
.mat-checkbox-layout {
  white-space: normal !important;
}

// mat-select no longer showing on top of input
.mat-select-panel-wrap {
  position: relative;
  top: 40px;
}

.compact {
  .mat-form-field {
    line-height: 1.125;

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      bottom: 0;
    }
  }
}
